import React from "react"
import { graphql } from "gatsby"
import Layout from "../../components/layout"
import SEO from "../../components/seo"
import ContactForm from "../../components/contactForm"
import Hero from "../../components/hero"
import Services from "../../components/services2"
import Content from "../../components/content"

/**
 * GraphQL Query to retrieve page data
 *
 * @return {Object}
 */
export const ServicesPageQuery = graphql`
  {
    site {
      siteMetadata {
        email
        phone
      }
    }
    hero: file(relativePath: { eq: "pages/about/header-home-erick.jpg" }) {
      sharp: childImageSharp {
        fluid(
          quality: 100
          maxWidth: 1920
          duotone: { highlight: "#15181f", shadow: "#15181f", opacity: 55 }
        ) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
  }
`

/**
 * Services Page Component
 * This component is used to generate the Services page
 *
 * @param {Object} props
 */
const ReconocimientoHijosPage = ({ data }) => {
  return (
    <Layout isSecondary={true}>
      <SEO title="Reconocimiento de hijos" description="" />

      <Hero
        type="simple"
        tagline="Estos son los principales servicios jurídicos que le ofrecemos"
        title="Reconocimiento de hijos"
        background={data.hero.sharp.fluid}
      />

      <Content
        tagline=""
        title="Reconocimiento de hijos"
      >
        <p>
        El fundamento legal lo encontramos en el libro I artículo 211 del Código Civil de Guatemala.
        </p>
        <p>
        A muchos compañeros guatemaltecos les ha pasado que decidieron viajar a los Estados Unidos
        de América para darle un mejor futuro a su familia, pero resulta que dejaron a la esposa
        embarazada, luego de dar a luz la esposa tienen el problema que no pueden reconocer como
        su legítimo hijo (a) al nuevo integrante de la familia.
        </p>
        <p>
        El licenciado Erick Claveria le puede ayudar a resolver el reconocimiento voluntario de su hijo
        (a) y el Código civil de Guatemala establece dos formas:
        </p>
        <p>
      <ol>
       <li><strong>La primera a través de un mandato especial con representación, nombrando a su
         representante legal para comparecer ante RENAP o un notario en Guatemala para realizar
         dicho acto jurídico en Guatemala.<br>
        </br></strong></li>
	     <li><strong>Y la segunda realizando el acto jurídico de reconocer a su hijo (a) a través
         de una escritura pública.<br>
        </br></strong></li>	     
        </ol>
        </p>
        <p>
        En ambos casos se debe presentarse al registrador civil del <strong>Registro Nacional de las Personas
        de la Republica de Guatemala (RENAP)</strong> El testimonio de la escritura pública en la cual conste
        el reconocimiento de su hijo (a) para su inscripción y anotación posterior en la partida de
        nacimiento respectiva.
        </p>
      </Content>

      <Services />

      <ContactForm
        email={data.site.siteMetadata.email}
        phone={data.site.siteMetadata.phone}
      />
    </Layout>
  )
}

export default ReconocimientoHijosPage

